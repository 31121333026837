


import {h} from 'preact';
import LinksToSocialNetworks from "../../components/LinksToSocialNetworks/LinksToSocialNetworks";
import CasesGrid from "../../components/CasesGrid/CasesGrid";
import FeedbackSlider from "../../components/FeedbackSlider/FeedbackSlider";
import styles from "./style.scss"
import ReturnBack from "./ReturnBack";

const Success = () => {
    return (
        <main id="main-container" className={styles.wrapper} main-attr>
            <ReturnBack></ReturnBack>
            <h1>Образование</h1>
            <p>Обучение — целенаправленный процесс организации деятельности обучающихся по овладению знаниями, умениями,
                навыками и компетенцией, приобретению опыта деятельности, развитию способностей, приобретению опыта
                применения знаний в повседневной жизни и формированию у обучающихся мотивации получения образования в
                течение всей жизни.</p>
            <p>Дополнительное профессиональное образование направлено на удовлетворение образовательных и
                профессиональных потребностей, профессиональное развитие человека, обеспечение соответствия его
                квалификации меняющимся условиям профессиональной деятельности и социальной среды. Дополнительное
                профессиональное образование осуществляется посредством реализации дополнительных профессиональных
                программ (программ повышения квалификации и программ профессиональной переподготовки).</p>
            <p>К освоению дополнительных профессиональных программ допускаются: лица, имеющие среднее профессиональное и
                (или) высшее образование.</p>
            <p>Программа повышения квалификации направлена на совершенствование и (или) получение новой компетенции,
                необходимой для профессиональной деятельности, и (или) повышение профессионального уровня в рамках
                имеющейся квалификации. Минимальное количество часов по программе повышения квалификации 16
                академических часов.</p>
            <p>Программа профессиональной переподготовки направлена на получение компетенции, необходимой для выполнения
                нового вида профессиональной деятельности, приобретение новой квалификации. </p>
            <p>Содержание дополнительной программы повышения квалификации определяется образовательной программой,
                разработанной и утвержденной в ООО «ИТК», если иное не установлено настоящим Федеральным законом
                и другими федеральными законами, с учетом потребностей лица, организации, по инициативе которых
                осуществляется дополнительное профессиональное образование.</p>
            <p>Программы профессиональной переподготовки в ООО «ИТК» разрабатываются на основании установленных
                квалификационных требований, профессиональных стандартов и требований соответствующих федеральных
                государственных образовательных стандартов среднего профессионального и (или) высшего образования к
                результатам освоения образовательных программ.</p>
            <p>Формы обучения и сроки освоения дополнительных профессиональных программ определяются образовательной
                программой и (или) договором об образовании.</p>
            <p>Освоение дополнительных профессиональных образовательных программ завершается итоговой аттестацией
                обучающихся в форме, определяемой организацией, осуществляющей образовательную деятельность,
                самостоятельно.</p>
            <p>Лицам, успешно освоившим соответствующую дополнительную профессиональную программу и прошедшим итоговую
                аттестацию, выдаются удостоверение о повышении квалификации установленного образца.</p>
            <p>Все образовательные программы ведутся на русском языке. <a href="/assets/documents/3_prikaz-o-yazyke.pdf" target="_blank">
                Приказ (скачать)</a></p>

            <h2>Реализуемые программы:</h2>
            <p><strong>Наименование программы:</strong> Дополнительная программа повышения квалификации “Веб
                программирование”</p>
            <p><strong>Форма обучения:</strong> Очная</p>
            <p><strong>Нормативный срок обучения:</strong> 240 ч</p>
            <p><a href="/assets/documents/2_programma.pdf" target={"_blank"}>Программа (скачать)</a></p>
            <p><a href="/assets/documents/uch_plan.pdf" target={"_blank"}>Учебный план (скачать)</a></p>
            <p>Календарный учебный график составляется по мере набора и комплектования групп.
                <div></div>
            <a href="/assets/documents/cal_graphic.pdf" target={"_blank"}>Календарный учебный график (скачать)</a></p>
            <p><a href="https://obrnadzor.gov.ru/gosudarstvennye-uslugi-i-funkczii/7701537808-gosfunction/formirovanie-i-vedenie-federalnogo-reestra-svedenij-o-dokumentah-ob-obrazovanii-i-ili-o-kvalifikaczii-dokumentah-ob-obuchenii/" target={"_blank"}>
                Проверка удостоверений (дипломов) в единой базе ФИС ФРДО
            </a></p>

        </main>
    );
};

export default Success;
